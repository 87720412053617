.cap-sheet--Capability_43_API-Interface {
  background-position: -1px -1px;
}

.cap-sheet--Capability_43_Analysis_Correlation {
  background-position: -133px -1px;
}

.cap-sheet--Capability_43_Anomaly-Detection {
  background-position: -265px -1px;
}

.cap-sheet--Capability_43_AntiMalware {
  background-position: -397px -1px;
}

.cap-sheet--Capability_43_AntiSpam {
  background-position: -529px -1px;
}

.cap-sheet--Capability_43_AntiVirus {
  background-position: -661px -1px;
}

.cap-sheet--Capability_43_App-Visibility-Control {
  background-position: -793px -1px;
}

.cap-sheet--Capability_43_Block {
  background-position: -925px -1px;
}

.cap-sheet--Capability_43_Cage {
  background-position: -1057px -1px;
}

.cap-sheet--Capability_43_Central-Management {
  background-position: -1px -133px;
}

.cap-sheet--Capability_43_Certificate-Authority {
  background-position: -133px -133px;
}

.cap-sheet--Capability_43_Certificate-Services {
  background-position: -265px -133px;
}

.cap-sheet--Capability_43_ClientBased-Security {
  background-position: -397px -133px;
}

.cap-sheet--Capability_43_Cloud-Access-Security-Broker_CASB {
  background-position: -529px -133px;
}

.cap-sheet--Capability_43_Cloud-Security {
  background-position: -661px -133px;
}

.cap-sheet--Capability_43_Conduit {
  background-position: -793px -133px;
}

.cap-sheet--Capability_43_Conference-Bridge {
  background-position: -925px -133px;
}

.cap-sheet--Capability_43_DNS-Security {
  background-position: -1057px -133px;
}

.cap-sheet--Capability_43_Data-Integrity {
  background-position: -1px -265px;
}

.cap-sheet--Capability_43_Data-Loss-Prevention {
  background-position: -133px -265px;
}

.cap-sheet--Capability_43_Database {
  background-position: -265px -265px;
}

.cap-sheet--Capability_43_Device-Encryption {
  background-position: -397px -265px;
}

.cap-sheet--Capability_43_Device-Profiling {
  background-position: -529px -265px;
}

.cap-sheet--Capability_43_Device-Trajectory {
  background-position: -661px -265px;
}

.cap-sheet--Capability_43_Disk-Encryption {
  background-position: -793px -265px;
}

.cap-sheet--Capability_43_Distributed-Denial-of-Service-Protection {
  background-position: -925px -265px;
}

.cap-sheet--Capability_43_Email-Encryption {
  background-position: -1057px -265px;
}

.cap-sheet--Capability_43_Email-Security {
  background-position: -1px -397px;
}

.cap-sheet--Capability_43_FSO {
  background-position: -133px -397px;
}

.cap-sheet--Capability_43_Fabric-Switching {
  background-position: -265px -397px;
}

.cap-sheet--Capability_43_Federation {
  background-position: -397px -397px;
}

.cap-sheet--Capability_43_Fence {
  background-position: -529px -397px;
}

.cap-sheet--Capability_43_File-Trajectory {
  background-position: -661px -397px;
}

.cap-sheet--Capability_43_Firewall {
  background-position: -793px -397px;
}

.cap-sheet--Capability_43_Firewall-Virtual {
  background-position: -925px -397px;
}

.cap-sheet--Capability_43_Flow-Analytics {
  background-position: -1057px -397px;
}

.cap-sheet--Capability_43_Guard {
  background-position: -1px -529px;
}

.cap-sheet--Capability_43_HVAC {
  background-position: -133px -529px;
}

.cap-sheet--Capability_43_Host-Context {
  background-position: -265px -529px;
}

.cap-sheet--Capability_43_Identity {
  background-position: -397px -529px;
}

.cap-sheet--Capability_43_Intrusion-Detection {
  background-position: -529px -529px;
}

.cap-sheet--Capability_43_Intrusion-Prevention {
  background-position: -661px -529px;
}

.cap-sheet--Capability_43_L2-L3-Network {
  background-position: -793px -529px;
}

.cap-sheet--Capability_43_L2-L3-Network_Virtual {
  background-position: -925px -529px;
}

.cap-sheet--Capability_43_L2-Switching {
  background-position: -1057px -529px;
}

.cap-sheet--Capability_43_L2-Switching_Virtual {
  background-position: -1px -661px;
}

.cap-sheet--Capability_43_L3-Switching {
  background-position: -133px -661px;
}

.cap-sheet--Capability_43_Load-Balancer {
  background-position: -265px -661px;
}

.cap-sheet--Capability_43_Logging_Reporting {
  background-position: -397px -661px;
}

.cap-sheet--Capability_43_Malware-Sandbox {
  background-position: -529px -661px;
}

.cap-sheet--Capability_43_Microsegmentation {
  background-position: -661px -661px;
}

.cap-sheet--Capability_43_Mobile-Device-Management {
  background-position: -793px -661px;
}

.cap-sheet--Capability_43_Monitoring {
  background-position: -925px -661px;
}

.cap-sheet--Capability_43_Multi-Factor-Authentication {
  background-position: -1057px -661px;
}

.cap-sheet--Capability_43_Name-Resolution {
  background-position: -1px -793px;
}

.cap-sheet--Capability_43_Network-Anti-Malware {
  background-position: -133px -793px;
}

.cap-sheet--Capability_43_PPE {
  background-position: -265px -793px;
}

.cap-sheet--Capability_43_Policy-Configuration {
  background-position: -397px -793px;
}

.cap-sheet--Capability_43_Posture-Assessment {
  background-position: -529px -793px;
}

.cap-sheet--Capability_43_Quarantine {
  background-position: -661px -793px;
}

.cap-sheet--Capability_43_Remediate {
  background-position: -793px -793px;
}

.cap-sheet--Capability_43_Remote-Access {
  background-position: -925px -793px;
}

.cap-sheet--Capability_43_RoomAccess {
  background-position: -1057px -793px;
}

.cap-sheet--Capability_43_Routing {
  background-position: -1px -925px;
}

.cap-sheet--Capability_43_SOAR_XDR {
  background-position: -133px -925px;
}

.cap-sheet--Capability_43_Secure-API-Gateway {
  background-position: -265px -925px;
}

.cap-sheet--Capability_43_Secure-File-Share {
  background-position: -397px -925px;
}

.cap-sheet--Capability_43_Secure-X {
  background-position: -529px -925px;
}

.cap-sheet--Capability_43_ServerBased-Security {
  background-position: -661px -925px;
}

.cap-sheet--Capability_43_Storage {
  background-position: -793px -925px;
}

.cap-sheet--Capability_43_Switch {
  background-position: -925px -925px;
}

.cap-sheet--Capability_43_TLS-Offload {
  background-position: -1057px -925px;
}

.cap-sheet--Capability_43_Tagging {
  background-position: -1px -1057px;
}

.cap-sheet--Capability_43_Threat-Intelligence {
  background-position: -133px -1057px;
}

.cap-sheet--Capability_43_Time-Synchronization {
  background-position: -265px -1057px;
}

.cap-sheet--Capability_43_USB-Security {
  background-position: -397px -1057px;
}

.cap-sheet--Capability_43_User {
  background-position: -529px -1057px;
}

.cap-sheet--Capability_43_VDC_VPC_OTV_Microsegmentation {
  background-position: -661px -1057px;
}

.cap-sheet--Capability_43_VPN-Concentrator {
  background-position: -793px -1057px;
}

.cap-sheet--Capability_43_Video {
  background-position: -925px -1057px;
}

.cap-sheet--Capability_43_VideoCamera {
  background-position: -1057px -1057px;
}

.cap-sheet--Capability_43_Virtual-Private-Network {
  background-position: -1189px -1px;
}

.cap-sheet--Capability_43_Virtualized-Capabilities {
  background-position: -1189px -133px;
}

.cap-sheet--Capability_43_Voice {
  background-position: -1189px -265px;
}

.cap-sheet--Capability_43_Vulnerability-Management {
  background-position: -1189px -397px;
}

.cap-sheet--Capability_43_Web-Application-Firewall {
  background-position: -1189px -529px;
}

.cap-sheet--Capability_43_Web-Reputation-Filtering_DCS {
  background-position: -1189px -661px;
}

.cap-sheet--Capability_43_Web-Security {
  background-position: -1189px -793px;
}

.cap-sheet--Capability_43_Wireless-Connection {
  background-position: -1189px -925px;
}

.cap-sheet--Capability_43_Wireless-Intrusion-Detection-System {
  background-position: -1189px -1057px;
}

.cap-sheet--Capability_43_Wireless-Intrusion-Prevention-System {
  background-position: -1px -1189px;
}

.cap-sheet--Capability_43_Wireless-Rogue-Detection {
  background-position: -133px -1189px;
}
