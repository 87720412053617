@tailwind base;
@tailwind components;

@font-face {
  font-family: 'CiscoSansTTThin';
  src: url(./fonts/CiscoSansTTThin.woff2) format('woff2');
}

html {
  scrollbar-color: blue white;
  scrollbar-width: thin;
  background: #f2f2f2;
}

*::-webkit-scrollbar {
  width: 12px;
  height: 9px;
  border-radius: 6px;
}
*::-webkit-scrollbar-track {
  background: #dee2e6;
  border-radius: 6px;
}
*::-webkit-scrollbar-thumb {
  background: #adb5bd;
  border-radius: 6px;
  border: 1px solid white;
}
*::-webkit-scrollbar-thumb:hover {
  background: #6c757d;
}

@tailwind utilities;
